import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import moment from "moment";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const MailViewContent = ({ mailId, mailCheck, setMailCheck, type }) => {
  const { t } = useTranslation();
  const [replyBtn, setReplyBtn] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [sendbtn, setSendBtn] = useState(false);
  //----------------------------------------- API -------------------------------------------
  let singleMail = ApiHook.CallSingleMailDetails(
    mailId,
    mailCheck,
    setMailCheck
  );
  const replyMutation = ApiHook.CallReplyMail();
  const deleteMutation = ApiHook.CallDeleteMail();

  const filterHTML = (input) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = input;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const filterMessage = (msg) => msg.substring(1, msg.length - 1)

  const toggleAccordionItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const handleReply = (value) => {
    setValue(value);
    if (value !== "") {
      setSendBtn(true);
      const strippedValue = stripHtmlTags(value);
      setReplyMessage(strippedValue);
    }
  };


  const sendReply = () => {
    const lastIndex = data.data?.length - 1;
    console.log(lastIndex);
    console.log(data);

    const data1 = {
      parentMailId: data.data[lastIndex].id,
      subject: `Re:${data.data[lastIndex].subject}`,
      message: replyMessage,
    };
    replyMutation.mutate(data1, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res.data?.data);
          setMailCheck(true);
          setValue("");
          setReplyMessage("");
          setReplyBtn(false);
          setSendBtn(false)
        }
      },
    });
  };


  const deleteMail = () => {
    //   const lastIndex = data.data?.length - 1;
    //  const id = data.data[lastIndex].id
    console.log(mailId);
    deleteMutation.mutate(mailId, {
      onSuccess: (res) => {
        if (res.status) {
          toast.success(res.data?.data);
          setData([]);
        }
      },
    });
  };

  useEffect(() => {
    const lastIndex = data.data?.length - 1;
    if (lastIndex >= 0) {
      setExpandedItems([...Array(lastIndex).keys()]); // Collapse all items except the last one
    }
    if (singleMail.isSuccess) {
      setData(singleMail);
    }
  }, [singleMail.data]);

  useEffect(() => {
    setData([]);
    setReplyBtn(false);
  }, [type]);


  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-4 mt-4">
          <div className="btn-toolbar">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setReplyBtn(true)}
              >
                <i className="fa-solid fa-reply"></i> {t("reply")}
              </button>
              {/* <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                <i className="fa-solid fa-reply-all"></i> {t("replyAll")}
              </button> */}
              {/* <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setReplyBtn(true)}
              >
                <i className="fa-solid fa-share"></i> {t("forward")}
              </button> */}
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => deleteMail()}
              >
                <i className="fa-regular fa-trash-can"></i> {t("delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {data?.data && (
        <div className="message-body">
          <div className="accordion" id="accordionExample">
            {data?.data.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  {console.log(index)}
                  <button
                    className="accordion-button"
                    type="button"
                    aria-expanded={expandedItems.includes(index)}
                    onClick={() => toggleAccordionItem(index)}
                  >
                    <div className="sender-details">
                      <img
                        className="img-sm rounded-circle mr-3"
                        src="http://www.urbanui.com/dashflat/template/images/faces/face11.jpg"
                        alt=""
                      />
                      <div className="details">
                        <p className="msg-subject">
                          {t("subject")} : {item.subject}
                        </p>
                        <p className="sender-email">
                          {t("from")}: {item.fromUsername} (
                          <a className="maildtl" href="#">
                            {item.fromUserMail}
                          </a>
                          ) <br />
                          <span>
                            {t("date")}:{" "}
                            {moment(item.createdAt).format(
                              "ddd, MMM D, YYYY [at] h:mm A"
                            )}
                          </span>
                          <i className="mdi mdi-account-multiple-plus"></i>
                        </p>
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse ${expandedItems.includes(index) ? "collapse" : ""
                    } `}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="message-content">
                      {/* {filterMessage(JSON.stringify(item.message)).split("\\n").map((v, i) => (
                        <React.Fragment key={i}>
                          {console.log(item.message)}
                          {v}<br /></React.Fragment>
                      ))} */}
                      <div dangerouslySetInnerHTML={{ __html: item.message }} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* <button className="reply_btn" onClick={() => setReplyBtn(true)}>
              {t("reply")}
            </button> */}
          </div>

          {replyBtn && (
            <>
              <div>
                <ReactQuill
                  value={value}
                  onChange={handleReply}
                  style={{ height: "250px" }}
                />
              </div>
            </>
          )}
          {sendbtn && (
            <div style={{ textAlign: "end", padding: "0 10px" }}>
              <button
                className="reply_btn"
                style={{
                  zIndex: "1",
                  position: "relative",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
                onClick={() => sendReply()}
              >
                Send
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MailViewContent;
