import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { ApiHook } from "../../hooks/apiHook";

const Replicalink = ({ replicaLink }) => {
  const { t } = useTranslation();
  const qrCodeStyle = {
    width: `50vw`,
    height: 'auto',
    margin: 'auto',
    maxWidth: '100%', // Ensure the QR code doesn't exceed its container's width
  };
  return (
    <div className="col-md-3">
      <div className="joinings_viewBox teamperfomance">
        <div className="joinings_viewBox_head">
          <h5>{t("replica")}</h5>
        </div>
        <div >
        <QRCode value={replicaLink} size={256} style={qrCodeStyle} />
        </div>
       
      </div>
    </div>
  );
};

export default Replicalink;
