import React from 'react';

const PaymentSuccess = () => {
  return (
    <div className="payment-success-container">
      <div className="payment-success">
        <div className='round-shape'>
            <img src="/images/tick.png" alt="" />
        </div>
        <h2>Payment Successful!</h2>
        <p>Thank you for your purchase</p>
       
      </div>
    </div>
  );
};

export default PaymentSuccess;
