import SubmitButton from "../Common/buttons/SubmitButton";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import {
  numberDisplay,
  reverseNumberDisplay,
} from "../../utils/currencyNumberDisplay";
import { toast } from "react-toastify";
import { getEpins } from "../../utils/getEpinList";
import { ApiHook } from "../../hooks/apiHook";
import { BASE_URL } from "../../config/config";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import React, { Fragment, useEffect, useRef, useState } from "react";

const UpgradeComponents = ({ data,handleUpgradeClick ,currency,conversionFactor}) => {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setValue, watch } = useForm();
  const formValues = watch();
  const [payment, setPayment] = useState(false);
  const [epinValues, setEpinValues] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const [getEwallet, setGetEwallet] = useState(false);
  const [transPass, setTransPass] = useState();
  const [transPassCheck, setTransPassCheck] = useState(false);

  const [transPassResposne, setTransPassResposne] = useState({
    success: null,
    error: null,
  });
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });
  const [file, setFile] = useState(null);
  const user = useSelector((state) => state?.user?.loginResponse?.user);
  const username = user ? JSON.parse(user) : null;
  // const conversionFactor = useSelector(
  //   (state) => state?.user?.conversionFactor
  // );

  // ----------------------------------- API --------------------------------------
  const paymentMethods = ApiHook.CallPaymentMethods("upgradation");
  const userBalance = ApiHook.CallEwalletBalance(getEwallet, setGetEwallet);
  const upgradationMutation = ApiHook.CallUpgradation();
  const transPassCheckData = ApiHook.CallTransPasswordCheck(
    transPass,
    transPassCheck,
    setTransPassCheck,
    setSubmitButtonActive,
    data?.renewalPrice,
    transPassResposne,
    setTransPassResposne
  );
  // API FOR BANK TRANSFER UPDATE
  const Upload = ApiHook.CallBankUpload(
    "upgrade",
    username?.username,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  );

  const epinList = getEpins(
    paymentMethods?.data?.epins,
    conversionFactor,
    currency
  );

  // useEffect(() => {
  //   const strokeDashOffsetValue =
  //     100 - (data?.productValidity?.packageValidityPercentage ?? 100);
  //   progressBarRef.current.style.strokeDashoffset = strokeDashOffsetValue;
  // }, [data?.productValidity?.packageValidityPercentage]);

  const handlePaymentTabClick = (tabId) => {
   
    setActiveTab(tabId);
    if (tabId === 2) {
      setSubmitButtonActive(false);
    } else if (tabId === 2) {
      setGetEwallet(true);
      setSubmitButtonActive(true);
      setValue("transactionPassword", transPass?.transPassword);
    } else {
      setSubmitButtonActive(true);
    }
    setValue("paymentMethod", tabId);
  
  };

  const handleEpinChange = (epinValues) => {
    let newValues = [];
    let totalEpinAmount = 0;
    setEpinValues(epinValues);
    Object.entries(epinValues)?.map(([key, value]) => {
      totalEpinAmount =
        totalEpinAmount + reverseNumberDisplay(String(value.amount));
      newValues.push(value.value);
    });
    const balance =
      Number(reverseNumberDisplay(String(formValues?.totalAmount))) -
      Number(totalEpinAmount);

    if (balance <= 0) {
      setValue("epinBalance", 0);
      setSubmitButtonActive(false);
      toast.success("Total amount achieved");
    } else {
      setSubmitButtonActive(true);
      setValue("epinBalance", numberDisplay(balance));
    }
    setValue("epins", newValues);
    setValue("totalEpinAmount", numberDisplay(totalEpinAmount));
  };

  const removeItemByIndex = (index) => {
    const updatedEpinValues = [...epinValues];
    const removedItem = updatedEpinValues.splice(index, 1)[0]; // Remove and get the removed item
    setEpinValues(updatedEpinValues);

    // Recalculate totalEpinAmount and balance

    const newTotalEpinAmount =
      Number(reverseNumberDisplay(String(formValues.totalEpinAmount))) -
      Number(reverseNumberDisplay(String(removedItem.amount.toFixed(2))));
    const newBalance =
      Number(reverseNumberDisplay(String(formValues?.totalAmount))) -
      newTotalEpinAmount;
    // Update the state values
    setValue("totalEpinAmount", numberDisplay(newTotalEpinAmount));
    setValue("epinBalance", numberDisplay(newBalance) ?? 0);

    if (newBalance <= 0) {
      setSubmitButtonActive(false);
    } else {
      setSubmitButtonActive(true);
    }
  };

  const handleTransPassword = async (item) => {
    const { value } = item;
    setTransPass({ transPassword: value });
    setTransPassResposne({
      success: null,
      error: null,
    });
  };

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    const type = "upgrade";
    if (file) {
      Upload.mutate(file, type);
    }
  };
  console.log(formValues, "formValues");
  const handleUpgrade = (product) => {
    setPayment(true);
    setValue("totalAmount", product.price - data.currentPack.price);
    setValue("upgradeProductId", product.id);
    
    setValue("oldProductId", data.currentPack.id);
  };

  const handleSubmit = () => {
    upgradationMutation.mutate(formValues, {
      onSuccess: (res) => {
        console.log(res);
        if (res.status) {
          setPayment(false);
          toast.success(t(res?.data));
          navigate("/profile");
        } else {
          toast.error(t(res?.data?.description), {
            backgroundColor: "blue",
          });
        }
      },
    });
  };

  return (
    <div className="package_upgrade_cnt_sec">
      <div className="plan-options">
        {data?.result.map((item, index) => (
          <>
            {!item?.currentPack && item?.upgradable ? (
              <div className="plan" key={index}>
                <div className="plan-header">
                
                  <h3 className="plan-heading">{item?.name}</h3>
                </div>
                <ul className="plan-feature">
                  <li>PV : {item?.pairValue}</li>
                  <li>Validity (months) : {item?.validity}</li>
                </ul>
                <p>
                  $<span className="plan-amount">{item?.price}</span>
                  {/* <button
                    className={"plan-choose"}
                    type="submit"
                    disabled={!item?.upgradable}
                    onClick={() => handleUpgrade(item)}
                  >
                    {t("upgrade")}
                  </button> */}
                  <SubmitButton
                      className="plan-choose"
                      text="upgrade"
                      isSubmitting={!item?.upgradable}
                      click={() => handleUpgradeClick(item)}
                    />
                </p>
              </div>
            ): !item?.currentPack && !item?.upgradable ? (
              <div className="plan current_plan" key={index}>
                <div className="plan-header">
                  <h3 className="plan-heading">{item?.name}</h3>
                </div>
                <ul className="plan-feature">
                  <li>PV : {item?.pairValue}</li>
                  <li>Validity (months) : {item?.validity}</li>
                </ul>
                <p>
                  
                  $<span className="plan-amount">{item?.price}</span>
                  <button className="plan-choose">Old Plan</button>
                </p>
              
              </div>
            ) : (
              <div className="plan current_plan" key={index}>
                <div className="plan-header">
                  <h3 className="plan-heading">{item?.name}</h3>
                </div>
                <ul className="plan-feature">
                  <li>PV : {item?.pairValue}</li>
                  <li>Validity (months) : {item?.validity}</li>
                </ul>
                <p>
                  
                  $<span className="plan-amount">{item?.price}</span>
                  <button className="plan-choose">Current Plan</button>
                </p>
              
              </div>
            )}
          </>
        ))}
      </div>
    
    </div>
  );
};

export default UpgradeComponents;


  {/* {payment && (
        <div>
          <div>
            <div className="regsiter_step_1_view_left_sec_head">
              Payment
              <br />
              <strong>
                {t("totalAmount")}: {currency.symbolLeft}{" "}
                {CurrencyConverter(formValues.totalAmount, conversionFactor)}
              </strong>
            </div>
          </div>
          <div className="col-lg-9 col-md-12 border-prf-left">
            <div className="row">
              <div className="col-md-3 pe-0">
                <div
                  className="nav nav-tabs payment_tab_reg"
                  id="nav-tab"
                  role="tablist"
                >
                  {paymentMethods?.data?.methods?.map((tab) => (
                    <Fragment key={tab.id}>
                      <button
                        key={tab.id}
                        className={`nav-link ${
                          activeTab === tab.id ? "active" : ""
                        }`}
                        id={`nav-${tab.id}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-${tab.id}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-${tab.id}`}
                        onClick={() => handlePaymentTabClick(tab.id, tab.title)}
                      >
                        <i className={tab?.icon}></i> {tab.title}
                      </button>
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className="col-md-9 ps-0">
                <div
                  className="tab-content p-3 border mb-5 payment__tab_cnt"
                  id="nav-tabContent"
                >
                  {activeTab === "" && (
                    <p>
                      <strong>{t("preferredPaymentPlaceholder")}</strong>
                    </p>
                  )}
                  {paymentMethods?.data?.methods?.map((tab) => (
                    <div
                      key={tab.id}
                      className={`tab-pane fade ${
                        activeTab === tab.id ? "show active" : ""
                      }`}
                      id={`nav-${tab.id}`}
                    >
                      {tab.title === "e-pin" && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>{t("epin")}</label>
                              <MultiSelect
                                disableSearch
                                options={epinList}
                                value={epinValues}
                                onChange={handleEpinChange}
                                labelledBy="Select"
                                hasSelectAll={false}
                                disabled={formValues.epinBalance <= 0}
                                closeOnChangedValue={
                                  formValues.epinBalance <= 0
                                }
                              />
                            </div>
                          </div>
                          <div className="elawwet_blance_sec">
                            {epinValues.map((epinItem, index) => (
                              <div className="elawwet_blance_row" key={index}>
                                <span>{epinItem.value}</span>
                                <strong>
                                  {t("amount")}: {currency?.symbolLeft}
                                  {CurrencyConverter(
                                    epinItem.amount,
                                    conversionFactor
                                  )}
                                </strong>
                                <a
                                  href="#/"
                                  className="epin_ball_clear"
                                  onClick={() => removeItemByIndex(index)} // Call the remove function with the index
                                >
                                  <i className="fa fa-close"></i>
                                </a>
                              </div>
                            ))}
                          </div>
                          <div className="valid_epi_pin_box">
                            {t("valid_epin_data")}
                          </div>
                          <div className="total_epin_detail">
                            <table>
                              <tbody>
                                <tr>
                                  <td>{t("total_epin_amount")}</td>
                                  <td className="text-end">
                                    <strong>
                                      {currency?.symbolLeft}
                                      {CurrencyConverter(
                                        formValues?.totalEpinAmount,
                                        conversionFactor
                                      ) ?? 0}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("totalAmount")}</td>
                                  <td className="text-end">
                                    <strong>
                                      {currency?.symbolLeft}
                                      {CurrencyConverter(
                                        formValues?.totalAmount,
                                        conversionFactor
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("balance")}</td>
                                  <td className="text-end">
                                    <strong>
                                      {currency?.symbolLeft}
                                      {formValues?.epinBalance != undefined
                                        ? CurrencyConverter(
                                            formValues?.epinBalance,
                                            conversionFactor
                                          )
                                        : CurrencyConverter(
                                            formValues?.totalAmount,
                                            conversionFactor
                                          )}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {tab.title === "e-wallet" && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-2">
                              <p>{`${t("ewalletBalance")} : ${
                                currency.symbolLeft
                              } ${CurrencyConverter(
                                userBalance?.data?.balanceAmount,
                                conversionFactor
                              )}`}</p>
                              <br />
                              <label htmlFor="transactionPassword">
                                {t("transaction_password")}
                              </label>
                              <input
                                id="transactionPassword"
                                type="password"
                                placeholder=""
                                className="form-control"
                                name="transPassword"
                                onChange={(e) => handleTransPassword(e.target)}
                              />
                              {transPassResposne?.success && (
                                <div style={{ color: "green" }}>
                                  {t(transPassResposne?.success)}
                                </div>
                              )}
                              {transPassResposne?.error && (
                                <div style={{ color: "red" }}>
                                  {t(transPassResposne?.error)}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              href="#"
                              className="btn btn-sm btn-primary mt-3"
                              onClick={() => setTransPassCheck(true)}
                            >
                              {t("apply")}
                            </button>
                          </div>
                          <span className="error-message-validator">
                            {transPassCheckData.data?.message}
                          </span>
                        </div>
                      )}
                      {tab.title === "free-joining" && (
                        <p>
                          <strong>{t("freeJoinPlaceHolder")}</strong>
                        </p>
                      )}
                      {tab.title === "bank-transfer" && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-2">
                              <label htmlFor="fileUpload">
                                {t("file_upload")}
                              </label>
                              <input
                                id="fileUpload"
                                type="file"
                                placeholder=""
                                className="form-control"
                                name="fileUpload"
                                onChange={handleFileChange}
                              />
                              {fileResponse?.success && (
                                <div style={{ color: "green" }}>
                                  {t(fileResponse?.success)}
                                </div>
                              )}
                              {fileResponse?.error && (
                                <div style={{ color: "red" }}>
                                  {t(fileResponse?.error)}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              href="#"
                              className="btn btn-sm btn-primary mt-3"
                              onClick={handleUpload}
                              disabled={Upload?.isLoading}
                            >
                              {Upload?.isLoading ? "Uploading..." : t("upload")}
                            </button>
                          </div>
                          {Upload?.data?.status === true && (
                            <>
                              <img
                                src={`${BASE_URL}${Upload?.data?.data?.file?.path}`}
                                alt="receipt"
                                style={{
                                  width: "160px",
                                  height: "160px",
                                  padding: "5px",
                                }}
                              />
                              <div style={{ color: "green" }}>
                                {Upload?.data?.message}
                              </div>
                            </>
                          )}
                          {Upload?.data?.status === false && (
                            <span className="error-message-validator">
                              {Upload?.data?.message}
                            </span>
                          )}
                        </div>
                      )}
                      {tab.title === "stripe" && (
                        <p>
                          <strong>Addon Coming Soon</strong>
                        </p>
                      )}
                      {tab.title === "paypal" && (
                        <p>
                          <strong>Addon Coming Soon</strong>
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="plan-choose renew_btn mt-2"
              disabled={submitButtonActive}
              onClick={handleUpgradeClick}
            >
              {t("finish")}
            </button>
          </div>
        </div>
      )} */}