import API from "../../api/api";

export const renewService = {
  getSubscriptionDetails: async () => {
    const response = API.get(`get-subscription-details`);
    return response;
  },
  renewSubscription: async (data) => {
    const response = API.post(`renew-subscription`, JSON.stringify(data))
      return response;
  },
  upgradation: async (data) => {
    const response = API.post(`upgrade`, JSON.stringify(data))
      return response;
  },
};
