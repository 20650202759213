// const BASE_URL = "http://192.168.6.23:5001/";
// // const BASE_URL = "https://api.wappss.com/";
// // const BASE_URL = "https://node.infinitemlmsoftware.com/";
// // const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// // const DEFAULT_KEY='84091924-1c8d-441d-ad12-3611d1319158'

//local

// const BASE_URL = "http://192.168.9.47:5011/api/";

//demo
const PAYPAL_CLIENT_ID = "AWfCEYJTu6HO-Swtr32SVslkFEyUbdgCVIIBATtpWYzoHdEy9H-jdccZdhwPYKX9-5l7A-AjBu1RNIz1"
// const BASE_URL = "http://162.19.146.135:1338/api/";

//live

const BASE_URL = "https://app.capitalhispano.com/api";
// const PAYPAL_CLIENT_ID = "AQs9uJ4O3YAuo297wpkA_BwXHsSRUzCnvFcQTBJsEv_B-eutLb98QxdsQ1tAR6YOKU_MhDCbi9tEn0xF"

const DEFAULT_KEY = "0b8d966f-13ff-461e-9496-d432445d83ff"

export { BASE_URL, DEFAULT_KEY ,PAYPAL_CLIENT_ID}   